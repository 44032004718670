import React, { FC, useEffect, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe,
} from 'use-query-params';

import PDF from './pages/events/PDF';
import SignIn from './components/auth/SignIn';
import SettingsPage from './pages/SettingsPage';
import EventUsers from './pages/events/EventUsers';
import AuthLayout from './components/layouts/auth';
import Dashboard from './components/layouts/Dashboard';
import NotFoundPage from './pages/common/NotFoundPage';
import PrepModePage from './pages/events/PrepModePage';
import EventTimeline from './pages/events/EventTimeline';
import JoinEventPage from './pages/events/JoinEventPage';
import ForbiddenPage from './pages/common/ForbiddenPage';
import EventsHistory from './pages/events/EventsHistory';
import EventNotifPage from 'pages/events/EventNotifPage';
import EventsUpcoming from './pages/events/EventsUpcoming';
import ForgotPassword from './components/auth/ForgotPassword';
import RestorePassword from './components/auth/RestorePassword';
import InlineAuth from './components/layouts/InlineAuth/InlineAuth';
import SharedTemplatePage from './pages/template/SharedTemplatePage';
import SharedPlaylistPage from './pages/songIdeas/SharedPlaylistPage';
import ForgotPasswordSuccess from './components/auth/ForgotPassword/ForgotPasswordSuccess';
import ResetPasswordSuccess from './components/auth/RestorePassword/RestorePasswordSuccess';

import Me from './pages/users/Me';
import AdminUsers from './pages/admin/Users';
import Profile from './components/user/Profile';
import Template from './pages/template/Template';
import Templates from './pages/template/Templates';
import SongIdeas from './pages/songIdeas/SongIdeas';
import ScannerPage from './pages/ScannerPage';
import EventGeneral from './pages/events/EventGeneral';
import AuthSpotify from './components/auth/AuthSpotify';
import ChildDjProfile from './pages/users/ChildDjProfile';
import AppleMusicConnect from './pages/common/AppleMusicConnect';
import SongIdeasDetails from './pages/songIdeas/SongIdeasDetails';
import { GA4ContextProvider } from './components/context/GA4Context';

import moment from './services/moment';

import useCommonStyles from './resources/styles/global/app/style';

const BeatsourcePage: FC = lazy(() => import('./pages/BeatsourcePage'));
const CheckoutPage: FC = lazy(() => import('./pages/CheckoutPage'));
const MarketingAssetsPage: FC = lazy(() => import('./pages/MarketingAssetsPage'));
const AcademyPage: FC = lazy(() => import('./pages/AcademyPage'));
const LessonDetailsPage: FC = lazy(() => import('./pages/LessonDetailsPage'));
const CheckoutFailPage: FC = lazy(() => import('./pages/CheckoutFailPage'));
const CheckoutSuccessPage: FC = lazy(() => import('./pages/CheckoutSuccessPage'));
const HealthCheckPage: FC = lazy(() => import('./pages/admin/HealthCheckPage'));
const AuthBeatsourcePage: FC = lazy(() => import('./pages/AuthBeatsourcePage'));
const FavoriteSectionsPage: FC = lazy(() => import('./pages/events/FavoriteSectionsPage'));
const CalendarPage: FC = lazy(() => import('./pages/CalendarPage'));
const WebHookPage: FC = lazy(() => import('./pages/admin/WebHookPage'));

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

const Routes = () => {
  const { i18n } = useTranslation();

  useCommonStyles();

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  return (
    // @ts-ignore
    <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
      <GA4ContextProvider>
        <Switch>
          <Route path={'/spotify-connect'} component={AuthSpotify} exact />
          <InlineAuth path={'/spotify-connect'} component={AuthSpotify} />
          <InlineAuth path={'/checkout'} component={CheckoutPage} exact />
          <AuthLayout path={'/forgot-password'} component={ForgotPassword} />
          <AuthLayout path={'/forgot-password-success'} component={ForgotPasswordSuccess} />
          <AuthLayout path={'/reset/:token'} exact={false} component={RestorePassword} />
          <AuthLayout path={'/reset-password-success'} component={ResetPasswordSuccess} />
          <AuthLayout path={'/login'} component={SignIn} />
          <Dashboard path={'/event/:id/timeline/:sectionId?'} component={EventTimeline} exact />
          <Dashboard path={'/calendar'} component={CalendarPage} exact />
          <Dashboard path={'/event/:id/general'} component={EventGeneral} exact />
          <Dashboard path={'/event/:id/users'} component={EventUsers} exact />
          <Dashboard path={'/event/:id/prepMode/:sectionId?'} component={PrepModePage} exact />
          <Dashboard path={'/event/:id/beatsource/:sectionId?'} component={BeatsourcePage} exact />
          <Dashboard
            path={'/favorite-sections/:sectionId?'}
            component={FavoriteSectionsPage}
            exact
          />
          <Dashboard path={'/event/:id/pdf'} component={PDF} exact />
          <Dashboard path={'/songIdeas/v/:id'} component={SongIdeasDetails} exact />
          <Dashboard path={'/songIdeas/:type'} component={SongIdeas} exact />
          <Dashboard path={'/templates/:type?'} component={Templates} exact />
          <Dashboard path={'/template/:id/:page/:sectionId?/:tab?'} component={Template} exact />
          <Dashboard path={'/scanner'} component={ScannerPage} exact />
          <Dashboard path={'/settings'} component={SettingsPage} exact />
          <Dashboard path={'/account'} component={Profile} exact />
          <Dashboard path={'/me'} component={Me} exact />
          <Dashboard path={'/profile/:id'} component={ChildDjProfile} exact />
          <Dashboard path={'/admin'} component={AdminUsers} exact />
          <Dashboard path={'/health-check'} component={HealthCheckPage} exact />
          <Dashboard path={'/marketing-assets'} component={MarketingAssetsPage} exact />
          <Dashboard path={'/academy'} component={AcademyPage} exact />
          <Dashboard path={'/lesson/:courseId/:id'} component={LessonDetailsPage} exact />
          <Dashboard path={'/events/upcoming'} component={EventsUpcoming} />
          <Dashboard path={'/events/history'} component={EventsHistory} />
          <Dashboard path={'/event/:id/notifications'} component={EventNotifPage} />
          <Dashboard path={'/redirect-beatsource-auth-result'} component={AuthBeatsourcePage} />
          <Dashboard path={'/web-hook-test'} component={WebHookPage} exact />
          <Dashboard path={'/'} component={() => <Redirect to="/events/upcoming" />} exact />
          <Route path={'/forbidden'} component={ForbiddenPage} exact />
          <Route path={'/join-event'} component={JoinEventPage} exact />
          <Route path={'/share-template'} component={SharedTemplatePage} />
          <Route path={'/share-playlist'} component={SharedPlaylistPage} />
          <Route path={'/connect-applemusic'} component={AppleMusicConnect} exact />
          <Route path={'/checkout-success'} component={CheckoutSuccessPage} />
          <Route path={'/checkout-fail'} component={CheckoutFailPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </GA4ContextProvider>
    </QueryParamProvider>
  );
};

export default Routes;
