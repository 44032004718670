import React, { FC, createContext, useContext, useMemo } from 'react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { useEventColor } from 'graphql/hooks/event';
import { isGuest, isHost } from 'services/users/helpers';
import {
  getPraparedEventWithTier,
  isBrandingActive,
  isCoverPhotoActive,
} from 'utils/helpers/proTier';

import {
  ADD_EVENT_SECTION_TO_FAVORITE,
  ADD_SECTION_FROM_FAVORITE_EVENT,
  ADD_SECTION_FROM_FAVORITE_TEMPLATE,
  ADD_TEMPLATE_SECTION_TO_FAVORITE,
  CLONE_TEMPLATE_SECTION,
  CLONE_EVENT_SECTION,
  CLONE_FAVORITE_SECTION,
} from 'graphql/mutations/sections';

import { Page, SectionVisibilityRoles } from 'types/enums';
import { EventMutations, TEventContext, TEventContextProviderProps } from './interfaces';

export const EventContext = createContext<TEventContext>(undefined!);

export const useEvent = () => useContext(EventContext);

export const EventContextProvider: FC<{
  value: TEventContextProviderProps;
}> = ({ children, value }) => {
  const isEventGuest = isGuest(value.event);
  const isEventHost = isHost(value.event);
  const isEventDj = !isEventGuest && !isEventHost;
  const isHistory = !!value.event?.isDeleted || !!value.event?.isPast;

  const isPrepMode = value.page === Page.prepMode;

  const isCoverPhotoAllowed = useMemo(() => isCoverPhotoActive(value.event?.allowedFeatures), [
    value.event?.allowedFeatures,
  ]);
  const isBrandingAllowed = useMemo(() => isBrandingActive(value.event?.allowedFeatures), [
    value.event?.allowedFeatures,
  ]);

  const isDontPlayVisible =
    isEventDj || (isEventHost && value.event?.dontPlayVisibility === SectionVisibilityRoles.host);

  const preparedEvent = getPraparedEventWithTier(value.event, isBrandingAllowed);

  useEventColor({
    event: value.event as EventItem,
    isBrandingAllowed,
  });

  const canAddGuest =
    isEventDj ||
    (isEventGuest && value?.event?.settings?.canGuestsInviteGuests) ||
    (isEventHost && value?.event?.settings?.canHostsInviteGuests);
  const showSectionInfo = !isEventGuest;
  const isTemplate = !isUndefined(get(value?.event, 'isPublic'));
  const isFavoriteSections = useMemo(() => value.page === Page.favoriteSections, [value.page]);

  const isEventLocked = !!value?.event?.isLocked;
  const isEditable = isTemplate ? get(value?.event, 'canEdit') : isEventDj && !isPrepMode;

  const canAddSection =
    isEditable ||
    (isEventHost && value?.event?.settings?.canHostCreateSections && !value?.event?.isLocked);
  const canEditSection = isEditable || (isEventHost && !isEventLocked);
  const canAddSong =
    isFavoriteSections || isTemplate
      ? false
      : isEventDj || (!isEventDj && !value?.event?.isLocked && !value?.event?.isPast);
  const canReorderSections = isFavoriteSections
    ? false
    : isTemplate
    ? isEditable
    : (isEventDj && !isPrepMode) ||
      (isEventHost && !!value.event?.settings?.canHostReorderSections && !isEventLocked);

  const mutations = useMemo(
    () =>
      isFavoriteSections
        ? {
            cloneSection: CLONE_FAVORITE_SECTION,
            // TODO: REFACTOR THIS TO another global timeline context with independent mutations
            addSectionToFavorite: ADD_EVENT_SECTION_TO_FAVORITE,
            addSectionFromFavorite: ADD_SECTION_FROM_FAVORITE_EVENT,
          }
        : isTemplate
        ? {
            cloneSection: CLONE_TEMPLATE_SECTION,
            addSectionToFavorite: ADD_TEMPLATE_SECTION_TO_FAVORITE,
            addSectionFromFavorite: ADD_SECTION_FROM_FAVORITE_TEMPLATE,
          }
        : {
            cloneSection: CLONE_EVENT_SECTION,
            addSectionToFavorite: ADD_EVENT_SECTION_TO_FAVORITE,
            addSectionFromFavorite: ADD_SECTION_FROM_FAVORITE_EVENT,
          },
    [isTemplate, isFavoriteSections]
  ) as EventMutations;

  const timelineRoute = useMemo(
    () =>
      isFavoriteSections
        ? `/favorite-sections`
        : `/${isTemplate ? 'template' : 'event'}/${value.event?._id}/timeline`,
    [isTemplate, value.event?._id, isFavoriteSections]
  );

  return (
    <EventContext.Provider
      value={{
        ...value,
        event: preparedEvent as EventItem,
        isLoading: value.isLoading || false,
        showSectionInfo,
        isEventDj,
        isEventHost,
        isEventGuest,
        canAddGuest,
        isTemplate,
        isFavoriteSections,
        isHistory,
        canAddSection,
        canEditSection,
        canReorderSections,
        canAddSong,
        mutations,
        timelineRoute,
        isPrepMode,
        isCoverPhotoAllowed,
        isDontPlayVisible,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
