import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';

import Section from '../Section';
import Textarea from 'vibo-ui/Textarea';
import SectionTime from '../SectionTime';
import SectionTools from './SectionTools';
import ProLabel from 'components/labels/ProLabel';
import SectionBlocksInfo from './SectionBlocksInfo';
import AddSectionInMiddle from './AddSectionInMiddle';
import WithTimelineSection from '../WithTimelineSection';
import VisibilityLabel from 'components/labels/VisibilityLabel';
import TimelineSectionSongsInfo from './TimelineSectionSongsInfo';
import StopPropagationWrapper from '../../common/StopPropagationWrapper';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EventContext } from '../../context/EventContext';
import { TimelineSectionContext } from './TimelineSectionContext';
import { EditableContext } from 'components/common/EditableContext';

import { MAX_TITLE_LENGTH } from 'services/constants';
import { strippString } from 'services/common/stringHelpers';
import { isHeadlineSection, isDontPlaySection } from 'services/sections/helpers';

import { SectionType, SectionVisibilityRoles } from 'types/enums';
import { TimelineSectionProps } from './interfaces';

import useStyles from './style';

const TimelineSection = <TSection extends TimelineSectionBase>({
  section,
  isSelected,
  isDragging,
  onClick,
  onDeleteClick,
  onEditClick,
  onNameChange,
  onTimeChange,
}: TimelineSectionProps<TSection>): React.ReactElement => {
  const classes = useStyles();

  const {
    isEventDj,
    isEventGuest,
    isEventHost,
    isFavoriteSections,
    canReorderSections,
    showSectionInfo = false,
    canAddSection = false,
  } = useContext(EventContext);

  const { canEdit } = useContext(EditableContext);

  const isHeadline = useMemo(() => isHeadlineSection(section), [section.type, section._id]);
  const isDontPlay = useMemo(() => isDontPlaySection(section), [section.type, section._id]);
  // @ts-ignore
  const isPro = useMemo(() => section.isPro, [section.isPro]);

  const isNameDisabledForHost = useMemo(
    () => isEventHost && !section.settings.canHostChangeSectionName,
    [isEventHost, section.settings.canHostChangeSectionName]
  );
  const isTimeHiddenForHost = useMemo(
    () => isEventHost && !section.settings.canHostChangeSectionTime,
    [isEventDj, section.settings.canHostChangeSectionTime]
  );

  const hasTimeRow: boolean = useMemo(
    () =>
      !isDontPlay &&
      section.settings.timeEnabled &&
      (canEdit || !!section.time) &&
      !isFavoriteSections,
    [isDontPlay, canEdit, section.settings.timeEnabled, section.time]
  );

  const notesContent = useMemo(() => !!strippString(section.note), [section.note]);

  const showSongsInfo = useMemo(() => section.settings.songsEnabled, [
    section.settings.songsEnabled,
  ]);
  const showQuestionsInfo = useMemo(() => section.settings.questionsEnabled && showSectionInfo, [
    section.settings.questionsEnabled,
    showSectionInfo,
  ]);
  const showNotesInfo = useMemo(
    () =>
      section.settings.notesEnabled &&
      notesContent &&
      (isEventDj || (isEventHost && !!section.settings.notesVisibleForHosts)),
    [
      section.settings.notesEnabled,
      notesContent,
      isEventDj,
      isEventHost,
      section.settings.notesVisibleForHosts,
    ]
  );
  const showVisibilityInfo = useMemo(
    () => section.visibility !== SectionVisibilityRoles.host && !isEventGuest,
    [section.visibility, isEventGuest]
  );

  return (
    <TimelineSectionContext.Provider
      value={{
        section,
        isDontPlay: false,
        isHeadLine: section.type === SectionType.headline,
      }}
    >
      <div
        className={classNames(classes.timelineSection, {
          headline: isHeadline,
        })}
      >
        {canAddSection && !isFavoriteSections ? (
          <AddSectionInMiddle sectionId={section._id} />
        ) : null}
        <Section
          content={{
            top: (
              <>
                {canReorderSections ? (
                  <Icon icon={IconmoonFont['dnd-16']} className="dndSectionIcon" />
                ) : null}
                <Textarea
                  className={classNames('editableSectionName', classes.editableSectionName, {
                    isDontPlay,
                  })}
                  onChange={(_, value) => {
                    !!value.trim().length && onNameChange?.(value);
                  }}
                  value={section.name}
                  maxLength={MAX_TITLE_LENGTH}
                  disabled={isEventGuest || isDontPlay || isNameDisabledForHost || !canEdit}
                  data-gtm-target-id="timeline-edit-section-name"
                />
                <div className="sectionExtra">
                  {hasTimeRow ? (
                    <StopPropagationWrapper>
                      <SectionTime
                        time={section.time}
                        onTimeChange={onTimeChange}
                        disabled={isTimeHiddenForHost || !canEdit}
                      />
                    </StopPropagationWrapper>
                  ) : null}
                  {canEdit ? (
                    <SectionTools
                      section={section}
                      // @ts-ignore
                      onEditClick={onEditClick}
                      // @ts-ignore
                      onDeleteClick={onDeleteClick}
                    />
                  ) : null}
                </div>
              </>
            ),
            middle:
              section.songsCount && section.settings.songsEnabled && section.songsCount === 1 ? (
                <WithTimelineSection exludedTypes={[SectionType.headline]} section={section}>
                  <TimelineSectionSongsInfo section={section} />
                </WithTimelineSection>
              ) : null,
            bottom: (
              <WithTimelineSection exludedTypes={[SectionType.headline]} section={section}>
                <>
                  <SectionBlocksInfo
                    showNotesInfo={showNotesInfo}
                    showSongsInfo={showSongsInfo}
                    showQuestionsInfo={showQuestionsInfo}
                  />
                  <div className="sectionLabels">
                    {isPro ? <ProLabel size="lg" /> : null}
                    {showVisibilityInfo ? (
                      <VisibilityLabel type={section.visibility} showTooltip={false} />
                    ) : null}
                  </div>
                </>
              </WithTimelineSection>
            ),
          }}
          isSelected={isSelected}
          isDragging={isDragging}
          onClick={() => onClick(section)}
          classes={{
            root: classNames({
              headline: isHeadline,
              dontPlay: isDontPlay,
            }),
            middle: classes.sectionMiddle,
          }}
        />
      </div>
    </TimelineSectionContext.Provider>
  );
};

export default TimelineSection;
