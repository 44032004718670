import { useContext, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';

import { ViboThemeContext } from 'components/context/ViboThemeContext';

import { validateHex } from 'services/common/colorsHelper';

import { LayoutBlockField, LayoutCheckField } from 'types/enums';
import { ModeColorsHex } from 'components/context/ViboThemeContext/interfaces';

export const useEventFileLayout = ({
  formik,
  layout,
  selectedOptionsIds,
}: useEventFileLayoutProps): useEventFileLayoutReturn => {
  const currentLayout = formik.values[layout] as EventFileLayoutConfig[];

  const checkedSections = currentLayout?.filter(({ selected }) => selected) || [];

  const resetSectionOptions = (options: EventFileLayoutConfig[]) =>
    formik.setFieldValue(layout, options);

  const updateAllSectionsLayoutField = (
    layoutOptions: EventFileLayoutConfig[],
    field: LayoutCheckField,
    blockField: LayoutBlockField
  ) => {
    const someOptionsSelected = (selectedOptionsIds?.length ?? 0) > 0;
    const options = someOptionsSelected
      ? layoutOptions?.filter(option => selectedOptionsIds?.includes(option._id))
      : layoutOptions;
    const optionsChecked = options
      .filter(op => op.selected && op[blockField])
      .every(layoutOption => layoutOption[field]);
    const allLayoutChecked = layoutOptions.every(layoutOption => layoutOption[field]);

    const newLayout = currentLayout?.map(option => {
      const isSelected = selectedOptionsIds?.some(id => id === option._id);

      return {
        ...option,
        [field]: option[blockField]
          ? someOptionsSelected
            ? isSelected
              ? !optionsChecked
              : option[field]
            : !allLayoutChecked
          : option[field],
      };
    });

    formik.setFieldValue(layout, newLayout);
  };

  const handleCheckAllSections = () =>
    updateAllSectionsLayoutField(
      currentLayout,
      LayoutCheckField.selected,
      LayoutBlockField.selected
    );

  const handleCheckAllNotes = () =>
    updateAllSectionsLayoutField(
      currentLayout,
      LayoutCheckField.showNotes,
      LayoutBlockField.notesEnabled
    );

  const handleCheckAllQuestions = () =>
    updateAllSectionsLayoutField(
      currentLayout,
      LayoutCheckField.showQuestions,
      LayoutBlockField.questionsEnabled
    );

  const handleCheckAllSongs = () =>
    updateAllSectionsLayoutField(
      currentLayout,
      LayoutCheckField.showSongs,
      LayoutBlockField.songsEnabled
    );

  const updateSectionFieldValue = (
    sectionId: string,
    field: LayoutCheckField
  ): EventFileLayoutConfig[] => {
    return currentLayout?.map(layoutOption => {
      const newOption = layoutOption;

      return {
        ...newOption,
        [field]: newOption._id === sectionId ? !newOption[field] : newOption[field] || false,
      };
    });
  };

  const handleCheckSection = (sectionId: string) => {
    const updatedLayout = updateSectionFieldValue(sectionId, LayoutCheckField.selected);

    formik.setFieldValue(layout, updatedLayout);
    formik.setFieldValue(
      `with${capitalize(layout)}`,
      updatedLayout.some(v => v.selected)
    );
  };

  const handleCheckSections = (sectionIds: string[]) => {
    const updatedSections = currentLayout?.map(section => {
      return {
        ...section,
        selected: sectionIds?.includes(section?._id) || false,
      };
    });

    const oldSet = updatedSections.map(section => section.selected);
    const newSet = get(formik, `values.${layout}`).map(
      (section: EventFileLayoutConfig) => section.selected
    );

    if (!!newSet && !isEqual(oldSet, newSet)) {
      formik.setFieldValue(layout, updatedSections);
    }
  };

  const handleSongCheck = (sectionId: string) =>
    formik.setFieldValue(layout, updateSectionFieldValue(sectionId, LayoutCheckField.showSongs));

  const handleNoteCheck = (sectionId: string) =>
    formik.setFieldValue(layout, updateSectionFieldValue(sectionId, LayoutCheckField.showNotes));

  const handleQuestionCheck = (sectionId: string) =>
    formik.setFieldValue(
      layout,
      updateSectionFieldValue(sectionId, LayoutCheckField.showQuestions)
    );

  const enabledQuestions =
    currentLayout?.filter(
      ({ questionsEnabled, showQuestions, selected }) =>
        questionsEnabled && showQuestions && selected
    ) || [];
  const enabledNotes =
    currentLayout?.filter(({ notesEnabled, showNotes }) => notesEnabled && showNotes) || [];
  const enabledSongs =
    currentLayout?.filter(({ songsEnabled, showSongs }) => songsEnabled && showSongs) || [];

  const isSomeQuestionsOn = currentLayout?.filter(
    ({ showQuestions, questionsEnabled, selected }) => showQuestions && questionsEnabled && selected
  ).length;
  const allQuestionsChecked = isSomeQuestionsOn
    ? currentLayout
        ?.filter(({ questionsEnabled, selected }) => questionsEnabled && selected)
        ?.every(({ showQuestions }) => showQuestions)
    : currentLayout?.every(
        ({ showQuestions, questionsEnabled }) => showQuestions && questionsEnabled
      );

  const isSomeSongsOn = currentLayout?.filter(
    ({ showSongs, songsEnabled, selected }) => showSongs && songsEnabled && selected
  )?.length;
  const allSongsChecked = isSomeSongsOn
    ? currentLayout
        ?.filter(({ songsEnabled, selected }) => songsEnabled && selected)
        ?.every(({ showSongs }) => showSongs)
    : currentLayout?.every(({ showSongs, songsEnabled }) => showSongs && songsEnabled);

  const isSomeNotesOn = currentLayout?.filter(
    ({ showNotes, notesEnabled, selected }) => showNotes && notesEnabled && selected
  )?.length;
  const allNotesChecked = isSomeNotesOn
    ? currentLayout
        ?.filter(({ notesEnabled, selected }) => notesEnabled && selected)
        ?.every(({ showNotes }) => showNotes)
    : currentLayout?.every(({ showNotes, notesEnabled }) => showNotes && notesEnabled);

  return {
    resetSectionOptions,
    handleCheckAllSections,
    handleCheckAllNotes,
    handleCheckAllSongs,
    handleCheckAllQuestions,
    handleCheckSection,
    handleCheckSections,
    handleSongCheck,
    handleNoteCheck,
    handleQuestionCheck,
    checkedSections,
    someQuestionsEnabled: enabledQuestions.length > 0,
    someNotesEnabled: enabledNotes.length > 0,
    someSongsEnabled: enabledSongs.length > 0,
    allSongsEnabled: currentLayout?.every(({ songsEnabled }) => songsEnabled),
    allQuestionsEnabled: currentLayout?.every(({ questionsEnabled }) => questionsEnabled),
    allNotesEnabled: currentLayout?.every(({ notesEnabled }) => notesEnabled),
    allQuestionsChecked,
    allNotesChecked,
    allSongsChecked,
    allSectionsChecked: currentLayout.every(({ selected }) => selected),
    allSectionsCheckedCount: checkedSections.length,
  };
};

export const useEventColor: (props: {
  event: EventItem | Template;
  isBrandingAllowed?: boolean;
}) => void = ({ event, isBrandingAllowed = false }) => {
  const { isReady, setModeColorsHex } = useContext(ViboThemeContext);

  const lightEventColor = useMemo(() => {
    const lColor =
      event?.eventColors?.customEventLightModeColor ||
      event?.eventColors?.defaultEventLightModeColor;

    return validateHex(lColor) ? lColor : null;
  }, [
    event?.eventColors?.customEventLightModeColor,
    event?.eventColors?.defaultEventLightModeColor,
  ]);
  const darkEventColor = useMemo(() => {
    const dColor =
      event?.eventColors?.customEventDarkModeColor || event?.eventColors?.defaultEventDarkModeColor;

    return validateHex(dColor) ? dColor : null;
  }, [event?.eventColors?.customEventDarkModeColor, event?.eventColors?.defaultEventDarkModeColor]);

  const eventColorsHex = useMemo(
    () => ({
      light: lightEventColor,
      dark: darkEventColor,
    }),
    [lightEventColor, darkEventColor]
  );

  const handleEventColorInApp = () => {
    setModeColorsHex(eventColorsHex as ModeColorsHex);
  };

  useEffect(() => {
    isReady && isBrandingAllowed && handleEventColorInApp();
  }, [eventColorsHex, isReady, isBrandingAllowed]);
};
