import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  section: {
    padding: '10px 0',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    fontWeight: 500,
    background: 'var(--background-paper)',
    color: 'var(--text-primary)',
    cursor: 'pointer',
    width: '100%',
    boxShadow: 'var(--shadows-5)',

    '& .editableSectionName': {
      height: 'auto!important',
      whiteSpace: 'pre-wrap!important',
    },

    '&:not(.selected), &.headline': {
      '& .sectionTime.withTime': {
        color: 'var(--text-primary)',
      },

      '& .progressBtn:not(.done)': {
        color: 'var(--primary-main)',
      },

      '& .editableSectionName:hover': {
        color: 'var(--text-primary)',
      },
    },

    '& .sectionExtra': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',
      gap: '4px',
      height: '30px',

      '& .threeDotsIcon': {
        opacity: 0,
      },

      '& .iconButton': {
        color: 'inherit',
      },

      '& .sectionTime:not(.withTime) .viboInput:not(:focus)': {
        opacity: 0,
      },

      '@media screen and (min-width: 1441px)': {
        height: '36px',
      },
    },

    '&.dontPlay': {
      '&:not(.selected)': {
        '&:before': {
          backgroundColor: 'var(--clr-carnation)',
        },
      },
    },

    '&:hover': {
      backgroundColor: 'var(--sectionHover)',

      '& .sectionExtra': {
        '& .viboToggleDropdown': {
          color: 'var(--primary-main)',
        },

        '& .threeDotsIcon': {
          opacity: 1,
        },

        '& .sectionTime .viboInput': {
          opacity: '1!important',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '12px 0',
    },
  },

  selected: {
    '&:not(.headline)': {
      color: 'var(--clr-white)',
      backgroundColor: 'var(--primaryMainToPrimaryLight)',

      '& .editableSectionName': {
        '&.textPreview, &.viboTextarea:not(:active)': {
          color: 'var(--clr-white)!important',

          '&:focus:not(.disabled):not(:disabled)': {
            color: `var(--text-primary)!important`,
            background: `var(--background-paper)!important`,
          },
        },
      },

      '& .dndSectionIcon, & .iconsWrapper, & .sectionExtra .viboToggleDropdown': {
        color: 'inherit',
      },

      '& .sectionTime': {
        '& .viboInput:not(:focus)': {
          '&, &::placeholder': {
            color: 'var(--clr-white)',
          },
        },

        '& .clearTimeWrapper:not(.focused)': {
          '& .clearIcon': {
            color: 'var(--clr-white)',
          },
        },
      },

      '&:not(.prepModeSection):not(.beatsourceSection)': {
        '&:hover': {
          '& .editableSectionName:hover:not(.disabled):not(:disabled):not(:focus)': {
            background: 'var(--primary-main)',
          },
        },
      },
    },

    '&:hover': {
      backgroundColor: 'var(--toryBlueToPrimaryLight)',

      '&:after': {
        borderLeftColor: 'var(--toryBlueToPrimaryLight)!important',
      },

      '& .text': {
        color: 'var(--background-paper)',
      },

      '& .editableSectionName': {
        '& > input': {
          background: 'var(--background-paper)',
        },
      },
    },

    '&.headline': {
      '&.section': {
        border: `1px solid var(--primary-main)!important`,
        backgroundColor: 'var(--selection-hover)',
      },
    },

    '&:not(.headline):not(.prepModeSection):not(.dontPlay)': {
      '& .placeholder': {
        '&:hover': {
          backgroundColor: 'var(--primary-main)',
        },
      },
    },
  },

  isDragging: {
    boxShadow: `inset 0 0 0 1px var(--primary-light)!important`,
  },

  sectionTop: {
    padding: '0 12px 0 20px',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '13px',
    lineHeight: '18.2px',
    gap: '4px',

    '& svg': {
      width: '16px',
      height: '16px',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 16px 0 26px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  middle: {
    margin: '10px 0 0 0',
    padding: '0 12px 0 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',

    '@media screen and (min-width: 1441px)': {
      padding: '0 16px 0 26px',
      margin: '12px 0 0 0',
    },
  },

  bottom: {
    margin: '10px 0 0 0',
    padding: '0 12px 0 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',

    '& > div:first-child': {
      width: 'fit-content',
      minWidth: '20px',
      display: 'flex',
      height: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 16px 0 26px',
      margin: '12px 0 0 0',
    },
  },
}));

export default useStyles;
