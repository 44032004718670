import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import CopyQuestionAnswer from 'components/questions/CopyQuestionAnswer';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useModal } from 'vibo-ui/Modal';
import {
  EMPTY_CONTACT_ANSWER,
  getContactResourceType,
  RESOURCE_ICON_BY_TYPE,
  RESOURCE_WRAPPER_BY_TYPE,
} from './constants';
import {
  useQuestionActions,
  useQuestion,
} from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { QuestionContactsModalProps } from 'components/modals/QuestionContactsModal';

import useStyles from '../../common/style';

const ContactAnswer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { answerQuestion } = useQuestionActions();
  const { canAnswer, question } = useQuestion();
  const { openModal } = useModal();

  const contact = useMemo(() => question.answer?.contact, [
    question.answer?.contact.name,
    question.answer?.contact.title,
    question.answer?.contact.resources,
  ]);

  const openQuestionContacsModal = () =>
    openModal<QuestionContactsModalProps>({
      key: Modals.questionContacts,
      props: {
        question: question,
        answerQuestion,
      },
    });
  const openConfirmDeleteModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('clearContact'),
        children: t('areSureWantClearContacts'),
        submit: {
          onClick: handleRemoveContact,
          text: t('clear'),
        },
      },
    });

  const handleRemoveContact = () => {
    answerQuestion(question._id)({
      answer: {
        ...question.answer,
        contact: EMPTY_CONTACT_ANSWER,
      },
    });
  };

  const withAnswer = useMemo(() => !!contact?.name || !!contact?.title, [
    contact?.name,
    contact?.title,
  ]);

  return (
    <div
      className={classNames('contactAnswer', classes.contactAnswer, {
        withAnswer,
      })}
    >
      {withAnswer ? (
        <div className="rowWithIconGroup">
          {!!contact?.name ? (
            <div className="rowWithIcon">
              <Icon icon={IconmoonFont['userV2-16']} />
              {contact.name}
            </div>
          ) : null}
          {!!contact?.title ? (
            <div className="rowWithIcon">
              <Icon icon={IconmoonFont['prizeBadge-16']} />
              {contact.title}
            </div>
          ) : null}
          {contact?.resources.map((resource, idx) => {
            const resourceType = getContactResourceType(resource);

            return resourceType ? (
              <div
                className="rowWithIcon"
                key={`timline-question-answer-resource-${idx}-${resource}`}
              >
                <Icon icon={RESOURCE_ICON_BY_TYPE[resourceType]} />
                {RESOURCE_WRAPPER_BY_TYPE[resourceType](resource)}
                <CopyQuestionAnswer value={resource} />
              </div>
            ) : null;
          })}
        </div>
      ) : null}
      <div className="answerButtons">
        <Button
          onClick={openQuestionContacsModal}
          disabled={!canAnswer}
          prefixIcon={IconmoonFont[withAnswer ? 'editPencil-16' : 'plus-16']}
          displayType="bordered"
          shape="round"
          size="lg"
        >
          {t(withAnswer ? 'editContact' : 'addContact')}
        </Button>
        {withAnswer ? (
          <Button
            onClick={openConfirmDeleteModal}
            disabled={!canAnswer}
            prefixIcon={IconmoonFont['delete-16']}
            displayType="bordered"
            shape="round"
            size="lg"
          >
            {t('clearContact')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ContactAnswer;
