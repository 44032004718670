import React, { FC } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';

import { ReactComponent as ViboLogoIcon } from 'resources/img/svg/viboLogo.svg';
import { ReactComponent as ViboLogoProIcon } from 'resources/img/svg/viboLogoPro.svg';

import { ViboLogoProps } from './interfaces';

const ViboLogo: FC<ViboLogoProps> = ({ className, isPro }) => (
  <Icon
    icon={isPro ? ViboLogoProIcon : ViboLogoIcon}
    className={classNames('viboLogo', className)}
    disableAutoFontSize
  />
);
export default ViboLogo;
